import React, { useState } from 'react';
import './App.css';

const App = () => {
  const [currentSection, setCurrentSection] = useState('hero');

  const handleNavClick = (section) => {
    setCurrentSection(section);
  };

  const renderSection = () => {
    switch (currentSection) {
      case 'hero':
        return <HeroSection />;
      case 'about':
        return <AboutSection />;
      case 'services':
        return <ServicesSection />;
      case 'contact':
        return <ContactSection />;
      default:
        return <HeroSection />;
    }
  };

  return (
    <div className="app">
      <Navbar onNavClick={handleNavClick} />
      {renderSection()}
      <Footer />
    </div>
  );
};

const Navbar = ({ onNavClick }) => (
  <nav className="navbar">
    <div className="navbar-brand">
      <span className="company-name" onClick={() => onNavClick('hero')}>
         Heptastar Intelligent Tech 
      </span>
      {/* <div class="company-name" onClick={() => onNavClick('hero')}>
        <span>Heptastar </span>
        <span>Intelligent</span>
        <span>Tech</span>
    </div> */}

    </div>
    <div className="navbar-buttons">
      <button onClick={() => onNavClick('about')}>About</button>
      <button onClick={() => onNavClick('services')}>Services</button>
      <button onClick={() => onNavClick('contact')}>Contact</button>
    </div>
  </nav>
);

const HeroSection = () => (
  <section className="hero-section">
    <h1>Welcome to Heptastar Intelligent Tech</h1>
    <h3>Your Partner in Ai Technology!</h3>

  </section>
);

const AboutSection = () => (
  <section className="about-section">
    <h2 className='section-titel'>About Us</h2>
    <p>
      Beijing Heptastar Intelligent Tech Ltd. is a tech company specializing in software development, AI, cloud computing, and more. We provide a wide range of services including software sales, outsourcing, and IT consulting.
    </p>
  </section>
);

const ServicesSection = () => {
  const services = [
    'Software Development',
    'Software Sales',
    'Software Outsourcing',
    'Network and Information Security',
    'Digital Cultural and Creative Software',
    'Artificial Intelligence Development',
  ];

  return (
    <section className="services-section">
      <h2 className='section-titel'>Our Services</h2>
      <div className="services-grid">
        {services.map((service, index) => (
          <div key={index} className="service-card">
            <h3>{service}</h3>
          </div>
        ))}
      </div>
    </section>
  );
};

const ContactSection = () => (
  <section className="contact-section">
 
    <h2 className='section-titel'>Contact Us</h2>
 
 
    <div className='contact-info'>
      <p>Phone: +86 135 1108 9609</p>
      <p>Email: customer_service@hit2023.com</p>
      <p>Address: 1st Floor, 2nd Building, Zhongguancun Software Park, Haidian District, Beijing, China</p>
    </div>
 
  </section>
);

const Footer = () => (
  <footer className="footer">
    <p>京ICP备2024067486号</p>
    <p>© 2023 Beijing Heptastar Intelligent Tech Ltd. All rights reserved.</p>




  </footer>
);

export default App;